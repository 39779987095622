<template>
    <div class="grid formgrid p-fluid">
        <div class="col-12">
            <Loader v-model="loading" />
            <Panel header="Facturacion">
                <div class="formgrid grid p-fluid">
                    <div class="col-12">
                        <FormToolbar :actions="['list', 'new']" @list="getInvoices" @new="newEntity" />
                        <div class="formgrid grid p-fluid">
                            <FormCalendar :wrapperClass="'field col-6'" :label="'Fecha Inicio'" v-model="entity.initial_date" />
                            <FormCalendar :wrapperClass="'field col-6'" :label="'Fecha Fin'" v-model="entity.final_date" />
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
        <div class="col-12">
            <br>
            <BasicDatatable :rows="entities" :selectionMode="'single'" :headers="headers" :contextMenu="true" :menuModel="[
                    {label: 'Facturar con GemaERP ©', icon: 'pi pi-fw pi-money-bill', action: 'generate-invoice'},
                    {label: 'Enviar Documentos', icon: 'pi pi-fw pi-send', action: 'send-docs'},
                ]" />
        </div>
    </div>
</template>
<script>
    import FormCalendar from "../../../components/general/FormCalendar.vue";
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import Loader from "../../../components/general/Loader.vue";
import formMixin from "../../../mixins/form";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { HeaderGrid, Rule } from '../../../utilities/General';
import { ticket } from "../../../models/ticket";

function getFirstDayOfMonth() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
}

export default {
    mixins: [formMixin],
    expose: ['refresh'],
    components: { Loader, FormCalendar, FormToolbar, BasicDatatable },
    data() {
        return {
            loading: false,
            validate: {
                valid: false,
                validations: {
                    initial_date: null,
                    final_date: null
                }
            },
            rules: [
                new Rule({ name: "initial_date" }),
                new Rule({ name: "final_date" })
            ],
            entity: {
                initial_date: getFirstDayOfMonth(),
                final_date: new Date(),
            },
            entities: [],
            headers: [
                new HeaderGrid("Fecha", "date", { type: "date-time"}),
                new HeaderGrid("#", "id_number"),
                new HeaderGrid("Cliente", "customer_name"),
                new HeaderGrid("RFC", "customer_rfc"),
                new HeaderGrid("Subtotal", "subtotal", { type: "currency"}),
                new HeaderGrid("IVA", "iva", { type: "currency"}),
                new HeaderGrid("Total", "total", { type: "currency"}),
            ]
        };
    },
    methods: {
        async getInvoices() {
            this.loading = true;
            try {
                this.entities = await new ticket(this.session).invoiceable(this.entity.initial_date, this.entity.final_date);
            } catch (error) {
                this.showError(error)
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            //   this.loading = true;
            this.entity = {
                initial_date: getFirstDayOfMonth(),
                final_date: new Date()
            };
            await this.getInvoices();
            //   try {
            //       this.entities = await this.entity.all();
            //   } catch (error) {
            //       this.showError(error);
            //   } finally {
            //       this.loading = false;
            //   }
        },
        newEntity() {
            this.entity = {
                initial_date: getFirstDayOfMonth(),
                final_date: new Date(),
            };
        },
    }
};
</script>
  
<style>

</style>
  
  