<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
      <Loader v-model="loading" />
        <Panel header="Administracion de Clientes">
          <TabView @tab-change="onTabChange" :activeIndex="tab">
            <TabPanel header="Cliente">
              <ClienteUI ref="cliente" />
            </TabPanel>
            <TabPanel header="Ordenes">
            </TabPanel>
            <TabPanel header="Facturacion">
              <FacturacionUI ref="facturacion" />
            </TabPanel>
        </TabView>
        </Panel>
    </div>
  </div>
</template>
<script>
import ClienteUI from "./Clientes/Cliente.vue";
import FacturacionUI from "./Clientes/Facturacion.vue";

import Loader from "../../components/general/Loader.vue";

export default {
  components: { Loader, ClienteUI, FacturacionUI },
  data() {
    return {
        tab:0,
        loading: false
    };
  },
  methods: {
    onTabChange(payload) {
      switch (payload.index) {
        case 0:
          this.$refs.cliente.refresh();
          break;
        case 2: 
          this.$refs.facturacion.refresh();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.$refs.cliente.refresh();
  }
};
</script>

<style>
</style>

